import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { YellowOwlContext } from '../../context/YellowOwlContext';
import {
  AGE_KEY,
  CATEGORY_ID_KEY,
  COUNTRY_CODE_KEY,
  DOB_KEY,
  EMAIL_KEY,
  PHONE_NUMBER_KEY,
  TENANT_ID_KEY,
  useLocalStorage,
  USER_ID_KEY,
  USER_NAME_KEY,
  USER_TYPE,
} from '../../context/LocalStorageContext';
import { REGISTRATION } from '../router';
export const UserDetailGuard: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { state, updateLocalStorage } = useLocalStorage();
  const yellowOwlApi = useContext(YellowOwlContext)!;
  const navigate = useNavigate();

  useEffect(() => {
    const checkUserDetails = async () => {
      const { userId, tenantId, categoryId, phoneNumber, parentEmail, loginMode, rollNumber } = state;

      if (!userId || !tenantId || !categoryId || (!phoneNumber && !parentEmail)) {
        let userDetailsResponse;

        // Simulate fetching user details
        if (loginMode === 'phoneNumber' && phoneNumber) {
          userDetailsResponse = await yellowOwlApi?.getUserDetails(phoneNumber);
        } else if (loginMode === 'email' && parentEmail) {
          userDetailsResponse = await yellowOwlApi?.getUserDetails(undefined, parentEmail);
        } else if (loginMode === 'rollNumber' && rollNumber) {
          userDetailsResponse = await yellowOwlApi?.getUserDetails(undefined, undefined, rollNumber);
        }

        const userDetails = userDetailsResponse?.data;

        if (userDetails) {
          updateLocalStorage(USER_ID_KEY, userDetails.userStrapiId ?? null);
          updateLocalStorage(USER_NAME_KEY, userDetails.userName);
          updateLocalStorage(DOB_KEY, userDetails.dateOfBirth ?? null);
          updateLocalStorage(EMAIL_KEY, userDetails.parentMailId);
          updateLocalStorage(AGE_KEY, userDetails.userAge.toString());
          updateLocalStorage(PHONE_NUMBER_KEY, userDetails.parentPhoneNumber ?? null);
          updateLocalStorage(COUNTRY_CODE_KEY, state.countryCode);
          updateLocalStorage(CATEGORY_ID_KEY, userDetails.commonCategoryId ?? null);
          updateLocalStorage(TENANT_ID_KEY, userDetails.tenantId ?? null);
          updateLocalStorage(USER_TYPE, userDetails.userType ?? null);

          if (!userDetails.parentPhoneNumber && !userDetails.parentMailId) {
            navigate(REGISTRATION);
          }
        } else {
          navigate(REGISTRATION);
        }
      }
    };
    checkUserDetails();
  }, [navigate]);

  return <>{children}</>;
};
